import React, { useRef, useState } from "react";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import CustomDatePicker from "../../../../../app/components/custom.date.picker";
import { TextField } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/styles";
import ToolTip from "../../../../../app/components/tooltip";

const cellData = [
  { width: "25%", text: "Milestone Name" },
  {
    width: "15%",
    text: "Override",
    tooltip: "Check to confirm that calculated date will be overwritten.",
  },
  { width: "25%", text: "Milestone Date" },
  { width: "35%", text: "Comments" },
];

const CountryMilestoneTable = ({
  rows,
  handleChange,
  isRightOrLeft = null,
}) => {
  const classes = useStyles();
  const inputClasses = useTextFieldStyles();

  const originalDates = useRef(
    rows.reduce((acc, row) => {
      acc[row.milestoneName] = row.milestoneDateMillis;
      return acc;
    }, {}),
  );

  const [isActiveCells, setIsActiveCells] = useState(
    rows.reduce((acc, row) => {
      acc[row.milestoneName] = row.isUserOverride;
      return acc;
    }, {}),
  );

  const getMinAllowedDate = (index) => {
    if (index < 1) return null;

    const prevDates = rows
      .slice(1, index)
      .map((row) => row.milestoneDateMillis);

    if (prevDates.length === 0) return null;

    const maxPrevDate = new Date(Math.max(...prevDates));
    maxPrevDate.setDate(maxPrevDate.getDate());

    return maxPrevDate;
  };

  const handleActiveCells = (milestoneName, value) => {
    setIsActiveCells((prevState) => ({
      ...prevState,
      [milestoneName]: value,
    }));

    if (!value) {
      handleChange(milestoneName, "comments", "");

      handleChange(
        milestoneName,
        "milestoneDateMillis",
        originalDates.current[milestoneName],
      );
    }
  };

  const handleCheckboxChange = (e, milestoneName) => {
    const newValue = e.target.checked ? 1 : 0;
    handleActiveCells(milestoneName, e.target.checked);
    handleChange(milestoneName, "isUserOverride", newValue);
  };

  // Modify the cellData based on isRightOrLeft
  const modifiedCellData =
    isRightOrLeft === "right"
      ? cellData.filter((cell) => cell.text !== "Override")
      : cellData;

  return (
    <TableContainer>
      <Table
        aria-label="customized table"
        className="table-container"
      >
        <TableHead className={classes.tableHead}>
          {modifiedCellData.map(({ width, text, tooltip }, index) => (
            <TableCell
              key={index}
              className={classes.tableCell}
              style={{ width }}
            >
              <div
                style={
                  tooltip
                    ? { display: "flex", alignItems: "center" }
                    : undefined
                }
              >
                <strong>{text}</strong>
                {tooltip && <ToolTip info={tooltip} />}
              </div>
            </TableCell>
          ))}
        </TableHead>
        <TableBody>
          {rows?.map((row, index) => (
            <TableRow key={index}>
              <TableCell
                className={classes.tableCell}
                component="th"
                scope="row"
              >
                {row.milestoneLabel}
              </TableCell>

              {isRightOrLeft !== "right" && (
                <TableCell className={classes.checkBoxCell}>
                  <FormControlLabel
                    style={{ margin: "0" }}
                    control={
                      <Checkbox
                        style={{
                          color: "var(--Primary-color)",
                        }}
                        checked={row.isUserOverride === 1}
                        onChange={(e) => {
                          handleCheckboxChange(e, row.milestoneName);
                        }}
                        name="isUserOverride"
                        disabled={[
                          "First Patient Last Follow-Up Visit",
                          "Last Patient Enrolled (LPE)",
                          "Last Patient Completes Treatment",
                          "Last Patient Last Follow-Up Visit",
                        ].includes(row.milestoneLabel)}
                      />
                    }
                  />
                </TableCell>
              )}

              <TableCell
                className={
                  isActiveCells[row.milestoneName]
                    ? classes.tableCell
                    : classes.disabledTableCell
                }
                component="th"
                scope="row"
              >
                <CustomDatePicker
                  label=""
                  value={row.milestoneDateMillis}
                  minDate={getMinAllowedDate(index)}
                  onChange={(value) => {
                    const normalizedDate = new Date(value);
                    normalizedDate.setUTCHours(12, 0, 0, 0); // Set to noon UTC
                    handleChange(
                      row.milestoneName,
                      "milestoneDateMillis",
                      normalizedDate.getTime(),
                    )
                  }}
                  showYearDropdown
                  showMonthDropdown
                  useShortMonthInDropdown
                  dateFormat={"dd/MMM/yyyy"}
                />
              </TableCell>

              <TableCell
                className={
                  isActiveCells[row.milestoneName]
                    ? classes.tableCell
                    : classes.disabledTableCell
                }
                component="th"
                scope="row"
              >
                <TextField
                  style={{ width: "100%" }}
                  label="Comments"
                  variant="outlined"
                  value={row.comments}
                  onChange={(e) =>
                    handleChange(row.milestoneName, "comments", e.target.value)
                  }
                  InputProps={{ classes: inputClasses }}
                  required
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CountryMilestoneTable;

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#ed1a3c",
    fontWeight: 600,
  },
  tableContainer: {
    textAlign: "center",
    border: "1px solid var(--Grey-4)",
  },
  tableHead: {
    background: "var(--Grey-1)",
    border: "1px solid var(--Grey-4)",
    textAlign: "center",
  },
  tableCell: {
    border: "1px solid var(--Grey-4)",
    textAlign: "left",
    padding: "5px",
  },
  disabledTableCell: {
    pointerEvents: "none",
    userSelect: "none",
    opacity: 0.4,
    border: "1px solid var(--Grey-4)",
    padding: "5px",
  },
  checkBoxCell: {
    border: "1px solid var(--Grey-4)",
    textAlign: "center",
    position: "relative",
    padding: "5px",
  },
}));

const useTextFieldStyles = makeStyles((theme) => ({
  input: {
    background: "white",
  },
}));
