import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import API from "../../app/utils/api";
import { useSnackbar } from "../../app/contexts/snackbar.context";
import EditIcon from "@material-ui/icons/Edit";
import CircularProgress from "@material-ui/core/CircularProgress";
import MuiPaper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import { SectionHeader } from "../../app/components";
import { makeStyles } from "@material-ui/styles";
import SeasonalEnrollmentVariation from "./seasonal.enrollment.variation";
import EditSiteGroupActivationProfiles from "./edit.sitegroup.modal/edit.seasonal.enrollment.variation";
import EditActivationProfiles from "./edit.sitegroup.modal/edit.activation.profiles";
import ActivationProfiles from "./activation.profiles";
import { Typography } from "@material-ui/core";
import { uniqueId } from "lodash";
import { useUser } from "../../app/contexts/user.context";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import DataBox from "./data.box";
import TertiaryButton from "../../app/components/buttons/tertiary";
import SecondaryButton from "../../app/components/buttons/secondary";
import PrimaryButton from "../../app/components/buttons/primary";
//import { handleShapeActivationProfilesForUI } from "./index";
export function handleShapeActivationProfilesForUI(activationProfiles) {
  const LENGTH_LIMIT = 11;
  const [baseActivationProfile] = activationProfiles;
  const activationProfilesCopy = [...activationProfiles];
  let formattedActivationProfiles = new Array(LENGTH_LIMIT);

  for (let i = 0; i < LENGTH_LIMIT; i++) {
    if (i <= activationProfilesCopy.length - 1) {
      formattedActivationProfiles[i] = activationProfilesCopy[i];
    } else {
      formattedActivationProfiles[i] = {
        countryId: baseActivationProfile.countryId,
        siteActivationProfileDays: "",
        id: uniqueId("ap-"),
        siteActivationProfilePct: "",
        siteGroupId: baseActivationProfile.siteGroupId,
        isNew: true,
      };
    }
  }
  return formattedActivationProfiles;
}
export default function SitegroupDetails(props) {
  const { id = 571 } = useParams();
  //const { sitegroups } = props;
  const [selected, setSelected] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [form, setForm] = useState(selected);
  const [loading, setLoading] = useState(false);
  const { showSnackbar } = useSnackbar();
  const classes = useStyles();
  //const inputClasses = useInputStyles();

  const { user } = useUser();
  const adminFlag = user?.groups?.includes("app_PremierPredict_AdminUser");
  //const adminFlag = true;
  useEffect(() => {
    API.getSingleAdminSiteGroup(id)
      .then((res) => {
        const [first] = res.data;
        setSelected({
          ...first,
          activationProfiles: handleShapeActivationProfilesForUI(
            first.activationProfiles,
          ),
        });
        setForm({
          ...first,
          activationProfiles: handleShapeActivationProfilesForUI(
            first.activationProfiles,
          ),
        });
      })
      .catch((err) => showSnackbar("Failed to fetch sitegroup", "error"));

    return () => {
      setSelected(null);
      setForm(null);
    };
  }, [id, showSnackbar]);

  const handleToggleEditMode = useCallback(() => {
    setEditMode((prev) => {
      if (prev) {
        setForm(selected);
        setEditMode(false);
      }
      return !prev;
    });
  }, [selected]);

  const handleChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      setForm((prev) => ({ ...prev, [name]: value }));
    },
    [setForm],
  );

  const handleChangeRollingSubmissions = useCallback(
    (value) => {
      setForm((prev) => ({
        ...prev,
        isRollingSubmission: value,
        firstSiteIdDays: value === 0 ? 0 : prev.firstSiteIdDays,
      }));
    },
    [setForm],
  );

  const handleChangeProfile = useCallback(
    (index, param, value) => {
      const newProfiles = [...form.activationProfiles];
      newProfiles[index] = {
        ...newProfiles[index],
        [param]: param === "siteActivationProfilePct" ? value : parseInt(value),
        hasUpdate: true,
      };
      setForm((prev) => ({ ...prev, activationProfiles: newProfiles }));
    },
    [setForm, form],
  );

  // const handleSaveActivationProfiles = useCallback(() => {
  //   const { activationProfiles } = form;
  //   const newRecords = [];
  //   const deletedRecords = [];
  //   const updatedRecords = [];

  //   activationProfiles.forEach((profile) => {
  //     const isNew =
  //       profile.isNew &&
  //       (profile.siteActivationProfilePct || profile.siteActivationProfileDays);
  //     const isDeleted =
  //       !profile.siteActivationProfilePct &&
  //       !profile.siteActivationProfileDays &&
  //       profile.siteActivationProfileId;
  //     const isUpdated =
  //       profile.hasUpdate &&
  //       (profile.siteActivationProfilePct || profile.siteActivationProfileDays);

  //     if (isNew) {
  //       newRecords.push(
  //         new Promise((res, rej) => res(API.createActivationProfiles(profile)))
  //       );
  //     } else if (isDeleted) {
  //       deletedRecords.push(
  //         new Promise((res, rej) =>
  //           res(API.deleteActivationProfiles(profile.siteActivationProfileId))
  //         )
  //       );
  //     } else if (isUpdated) {
  //       updatedRecords.push(
  //         new Promise((res, rej) => res(API.editActivationProfiles(profile)))
  //       );
  //     } else {
  //       console.log("no db trx");
  //     }
  //   });

  //   return [...newRecords, ...deletedRecords, ...updatedRecords];
  // }, [form]);

  // const handleSaveSeasonalEnrollment = useCallback(() => {
  //   const { enrollmentVariations } = form;
  //   const updatedRecords = [];

  //   enrollmentVariations.forEach((variation) => {
  //     const { hasUpdate } = variation;

  //     if (hasUpdate) {
  //       updatedRecords.push(
  //         new Promise((res, rej) => res(API.editEnrollmentVariation(variation)))
  //       );
  //     } else {
  //       console.log("no db trx");
  //     }
  //   });
  //   return updatedRecords;
  // }, [form]);
  // const handleSave = () => {
  //   setLoading(true);
  //   Promise.all([
  //     ...handleSaveActivationProfiles(),
  //     ...handleSaveSeasonalEnrollment(),
  //   ])
  //     .then(function (values) {
  //       API.patchAdminSiteGroup(form)
  //         .then((res) => {
  //           const activationProfiles = handleShapeActivationProfilesForUI(
  //             res.data.activationProfiles
  //           );
  //           setForm((prev) => ({
  //             ...res.data,
  //             activationProfiles,
  //           }));
  //           setSelected((prev) => ({
  //             ...res.data,
  //             activationProfiles,
  //           }));
  //           setLoading(false);
  //           handleClose();
  //         })
  //         .catch((err) => {
  //           setLoading(false);
  //           showSnackbar("Failed to save sitegroup.", "error");
  //         });
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //       showSnackbar("Something went wrong", "error");
  //     });
  // };
  const handleSave = () => {
    setLoading(true);
    // Create the payload object
    const payload = {
      ...form,
      activationProfiles: form.activationProfiles.map((profile) => ({
        ...profile,
        siteActivationProfilePct: profile.siteActivationProfilePct || null,
        siteActivationProfileDays: profile.siteActivationProfileDays || null,
      })),
      enrollmentVariations: form.enrollmentVariations,
    };

    API.updateAdminData(payload)
      .then((res) => {
        setLoading(false);
        setEditMode(false);
        setSelected(payload); // Update the selected state with the latest form data
      })
      .catch((err) => {
        setLoading(false);
        showSnackbar("Failed to save sitegroup.", "error");
      });
  };

  const handleCancel = useCallback(() => {
    setForm(selected);
    setEditMode(false);
  }, [selected]);

  if (!selected || !form) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div
      style={{
        overflow: "auto",
        height: "calc(100vh - 91px)",
      }}
    >
      <Paper classes={classes}>
        <Header>
          <HeaderDetails>
            <SectionHeader
              hasStartAdornment={false}
              marginBottom={false}
              useBackground={false}
            >
              {selected.siteGroupName}
            </SectionHeader>
            <Divider
              orientation="vertical"
              style={{ height: "28px" }}
            />
            <SectionHeader
              hasStartAdornment={false}
              marginBottom={false}
              isBold={false}
              useBackground={false}
            >
              Site Group ID: {selected.siteGroupId}
            </SectionHeader>
            <Divider
              orientation="vertical"
              style={{ height: "28px" }}
            />
            <SectionHeader
              hasStartAdornment={false}
              marginBottom={false}
              isBold={false}
              useBackground={false}
            >
              Country ID: {selected.countryId}
            </SectionHeader>
          </HeaderDetails>
          {adminFlag && (
            <TertiaryButton
              onClick={handleToggleEditMode}
              disabled={loading}
              startIcon={
                !editMode && <EditIcon style={{ marginRight: ".3em" }} />
              }
            >
              {editMode ? "Cancel" : "Edit"}
            </TertiaryButton>
          )}
        </Header>
        <Divider />
        <GridByTwo>
          <DataBox
            title="Time for Site ID (days)"
            tooltip={
              <div>
                <Typography>
                  <strong>Small: </strong>1-2 sites
                </Typography>
                <Typography>
                  <strong>Medium: </strong>3-10 sites
                </Typography>
                <Typography>
                  <strong>Large: </strong>10+ sites
                </Typography>
              </div>
            }
            content={
              <>
                <EditableField
                  label="Small"
                  value={form.timeSiteIdSmallDays}
                  onChange={handleChange}
                  name="timeSiteIdSmallDays"
                  editMode={editMode}
                />
                <EditableField
                  label="Medium"
                  value={form.timeSiteIdMediumDays}
                  onChange={handleChange}
                  name="timeSiteIdMediumDays"
                  editMode={editMode}
                />
                <EditableField
                  label="Large"
                  value={form.timeSiteIdLargeDays}
                  onChange={handleChange}
                  name="timeSiteIdLargeDays"
                  editMode={editMode}
                />
              </>
            }
          />
          <DataBox
            title="Country Start Up Time Frame (days)"
            content={
              <>
                <EditableField
                  label="Super Short"
                  value={form.timeCountryStartUpSuperShortDays}
                  onChange={handleChange}
                  name="timeCountryStartUpSuperShortDays"
                  editMode={editMode}
                />
                <EditableField
                  label="Short"
                  value={form.timeCountryStartUpShortDays}
                  onChange={handleChange}
                  name="timeCountryStartUpShortDays"
                  editMode={editMode}
                />
                <EditableField
                  label="Medium"
                  value={form.timeCountryStartUpMediumDays}
                  onChange={handleChange}
                  name="timeCountryStartUpMediumDays"
                  editMode={editMode}
                />
                <EditableField
                  label="Long"
                  value={form.timeCountryStartUpLongDays}
                  onChange={handleChange}
                  name="timeCountryStartUpLongDays"
                  editMode={editMode}
                />
              </>
            }
          />
          <DataBox
            title="Package Prep (days)"
            content={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "15px",
                }}
              >
                <EditableFieldShort
                  value={form.packagePrepDays}
                  onChange={handleChange}
                  name="packagePrepDays"
                  editMode={editMode}
                />
              </div>
            }
          />
          <DataBox
            title="Rolling Submission"
            content={
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "50%",
                    marginTop: "10px",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="rolling-yes"
                        color="primary"
                      />
                    }
                    checked={form.isRollingSubmission === 1}
                    onChange={() => handleChangeRollingSubmissions(1)}
                    label={
                      <span
                        style={{
                          color:
                            !editMode && form.isRollingSubmission === 1
                              ? "black"
                              : "inherit",
                          fontWeight:
                            !editMode && form.isRollingSubmission === 1
                              ? "bold"
                              : "normal",
                        }}
                      >
                        Yes
                      </span>
                    }
                    className={classes.checkbox}
                    disabled={!editMode}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="rolling-no"
                        color="primary"
                      />
                    }
                    checked={form.isRollingSubmission === 0}
                    onChange={() => handleChangeRollingSubmissions(0)}
                    label={
                      <span
                        style={{
                          color:
                            !editMode && form.isRollingSubmission === 0
                              ? "black"
                              : "inherit",
                          fontWeight:
                            !editMode && form.isRollingSubmission === 0
                              ? "bold"
                              : "normal",
                        }}
                      >
                        No
                      </span>
                    }
                    className={classes.checkbox}
                    disabled={!editMode}
                  />
                </div>
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <EditableField
                    label="Days to First Site ID"
                    value={form.firstSiteIdDays}
                    onChange={handleChange}
                    name="firstSiteIdDays"
                    editMode={editMode && form.isRollingSubmission === 1}
                  />
                </div>
              </div>
            }
          />
          <DataBox
            title="Time from Site Start-Up Activities Complete to FSA (days)"
            content={
              <EditableFieldShort
                value={form.timeSIVDays}
                onChange={handleChange}
                name="timeSIVDays"
                editMode={editMode}
              />
            }
          />
          <DataBox
            title="Time from First Activation to FPS (days)"
            content={
              <EditableFieldShort
                value={form.timeFirstPatientScreenDays}
                onChange={handleChange}
                name="timeFirstPatientScreenDays"
                editMode={editMode}
              />
            }
          />
        </GridByTwo>

        {editMode ? (
          <EditActivationProfiles
            sitegroup={form}
            onChange={handleChangeProfile}
            tooltip={
              <div>
                <Typography>
                <strong>Percentage: </strong>Cumulative percentage of sites initiated.
                </Typography>
                <Typography>
                  <strong>Days: </strong>Days since First SIV.
                </Typography>
              </div>
            }
          />
        ) : (
          <ActivationProfiles
            activationProfiles={selected.activationProfiles}
            tooltip={
              <div>
                <Typography>
                <strong>Percentage: </strong>Cumulative percentage of sites initiated.
                </Typography>
                <Typography>
                  <strong>Days: </strong>Days since First SIV.
                </Typography>
              </div>
            }
          />
        )}

        {editMode ? (
          <EditSiteGroupActivationProfiles
            sitegroup={form}
            setForm={setForm}
          />
        ) : (
          <SeasonalEnrollmentVariation
            enrollmentVariations={selected.enrollmentVariations}
          />
        )}
        {editMode && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "1em",
              marginTop: "1em",
            }}
          >
            <SecondaryButton
              onClick={handleCancel}
              disabled={loading}
            >
              Cancel
            </SecondaryButton>
            <PrimaryButton
              onClick={handleSave}
              disabled={loading}
            >
              {loading ? <CircularProgress size="1em" /> : "Save"}
            </PrimaryButton>
          </div>
        )}
      </Paper>
    </div>
  );
}

const Paper = styled(MuiPaper)`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1em;
  overflow: auto;
  height: calc(100vh - 91px) !important;
`;

const HeaderDetails = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 1em;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const GridByTwo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "fit-content",
    padding: "1em",
  },
  checkbox: {
    marginLeft: 16,
  },
}));

const EditableField = ({ label, value, onChange, name, editMode }) => {
  const classes = useInputStyles(editMode);

  return (
    <TextField
      label={label}
      value={value !== null && value !== undefined ? String(value) : ""}
      name={name}
      onChange={onChange}
      className={classes.input}
      disabled={!editMode}
      variant="standard"
      InputProps={{
        disableUnderline: true,
        startAdornment: <span className={classes.label}>{label}&nbsp;</span>,
        classes: {
          input: classes.inputText,
        },
      }}
      InputLabelProps={{
        shrink: true,
        className: classes.hiddenLabel,
      }}
    />
  );
};

const useInputStyles = makeStyles((theme) => ({
  input: {
    "& .MuiInput-root": {
      "&::before, &::after": {
        borderBottomStyle: "none",
      },
      "& input": {
        textAlign: "center",
      },
    },
  },
  label: {
    fontSize: theme.typography.body1.fontSize,
    width: "65%",
    textAlign: "center",
  },
  hiddenLabel: {
    display: "none",
  },
  inputText: {
    fontWeight: "bold",
    color: theme.palette.text.primary,
    width: "35%",
    textAlign: "center",
    borderWidth: (editMode) => (editMode ? "1px" : "0px"),
    borderStyle: "solid", // Set the border style (solid, dashed, dotted, etc.)
    borderColor: (editMode) => (editMode ? theme.palette.primary.main : "none"),
  },
}));

const EditableFieldShort = ({ label, value, onChange, name, editMode }) => {
  const classes = useInputStylesShort(editMode);

  return (
    <TextField
      label={label}
      value={value !== null && value !== undefined ? String(value) : ""}
      name={name}
      onChange={onChange}
      className={classes.input}
      disabled={!editMode}
      variant="standard"
      InputProps={{
        disableUnderline: true,
        startAdornment: <span className={classes.label}>{label}&nbsp;</span>,
        classes: {
          input: classes.inputText,
        },
      }}
      InputLabelProps={{
        shrink: true,
        className: classes.hiddenLabel,
      }}
    />
  );
};

const useInputStylesShort = makeStyles((theme) => ({
  input: {
    "& .MuiInput-root": {
      "&::before, &::after": {
        borderBottomStyle: "none",
      },
      "& input": {
        textAlign: "center",
      },
    },
  },
  label: {
    fontSize: theme.typography.body1.fontSize,
    width: "25%",
    textAlign: "center",
  },
  hiddenLabel: {
    display: "none",
  },
  inputText: {
    fontWeight: "bold",
    color: theme.palette.text.primary,
    width: "35%",
    textAlign: "center",
    borderWidth: (editMode) => (editMode ? "1px" : "0px"),
    borderStyle: "solid", // Set the border style (solid, dashed, dotted, etc.)
    borderColor: (editMode) => (editMode ? theme.palette.primary.main : "none"),
  },
}));
