import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Modal } from "../../../../../../app/components";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { useStudyPlan } from "../../../../../../app/contexts/study.context";
import SecondaryButton from "../../../../../../app/components/buttons/secondary";
import PrimaryButton from "../../../../../../app/components/buttons/primary";

const CheckboxItem = ({
  name,
  checked,
  onChange,
  label,
  useContainerContent = false,
}) => (
  <SubContainerRow>
    <SubContainer>
      {useContainerContent ? (
        <SubContainerContent>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={onChange}
                name={name}
              />
            }
            label={label}
          />
        </SubContainerContent>
      ) : (
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onChange={onChange}
              name={name}
            />
          }
          label={label}
        />
      )}
    </SubContainer>
  </SubContainerRow>
);

const ExportChartsModal = ({
  open = false,
  onClose,
  downloadImage,
  setChartIds,
  type,
}) => {
  const [isChecked, setIsChecked] = useState({});
  const { studyPlan } = useStudyPlan();

  // Reset selections when modal is closed
  useEffect(() => {
    if (!open) {
      setIsChecked({});
      setChartIds([]);
    }
  }, [open, setChartIds]);

  const handleClose = () => {
    onClose();
  };

  const handleChange = (e) => {
    const { name, checked } = e.target;
    setIsChecked((prev) => ({ ...prev, [name]: checked }));

    if (checked) {
      setChartIds((prevIds) => [...prevIds, name]);
    } else {
      setChartIds((prevIds) => prevIds.filter((id) => id !== name));
    }
  };

  const handleExport = () => {
    downloadImage();
    handleClose();
  };

  // Check if any checkbox is selected
  const isAnySelected = Object.values(isChecked).some((value) => value);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="Export Charts"
      aria-describedby="Export Charts"
      title="Export Charts"
      sx={{ minWidth: "20rem", maxWidth: "100%", width: "30rem" }}
    >
      <FormGroup>
        <Container>
          <CheckboxItem
            name="MonthlyEnrollment"
            checked={isChecked["MonthlyEnrollment"]}
            onChange={handleChange}
            label="Monthly Enrollment"
            useContainerContent={true}
          />
          <CheckboxItem
            name="WeeklyEnrollment"
            checked={isChecked["WeeklyEnrollment"]}
            onChange={handleChange}
            label="Weekly Enrollment"
            useContainerContent={false}
          />
        {(studyPlan.studyPlanTypeDetail.includes("Cohort") || studyPlan.studyPlanTypeDetail.includes("Combination")) && (
          <>
            <CheckboxItem
              name="PatientsByGroup"
              checked={isChecked["PatientsByGroup"]}
              onChange={handleChange}
              label="Patients By Group"
              useContainerContent={false}
            />
            <CheckboxItem
              name="CohortsTimeline"
              checked={isChecked["CohortsTimeline"]}
              onChange={handleChange}
              label={studyPlan.studyPlanTypeDetail.includes("Combination") ? "Timeline Overview" : "Cohorts Timeline"}
              useContainerContent={false}
            />
          </>
        )}
        </Container>
        <ButtonContainer>
          <SecondaryButton onClick={handleClose}>Cancel</SecondaryButton>
          <PrimaryButton
            onClick={handleExport}
            disabled={!isAnySelected}
          >
            Export
          </PrimaryButton>
        </ButtonContainer>
      </FormGroup>
    </Modal>
  );
};

export default ExportChartsModal;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1em;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

const SubContainerRow = styled.div`
  display: flex;
  gap: 0.5em;
`;

const SubContainer = styled.div`
  flex: 1;
`;

const SubContainerTitle = styled.div`
  font-weight: bold;
  margin-bottom: 0.25em;
`;

const SubContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25em;
`;
