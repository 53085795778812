import { LinearProgress } from "@material-ui/core";
import React, { useState } from "react";
import API from "../../../../../../app/utils/api";
import FileUploader from "../../../../../../app/components/file.uploader.excel";
import CustomModal from "../../../../../../app/components/modal.no.close";
import styled from "styled-components";
import { useSnackbar } from "../../../../../../app/contexts/snackbar.context";
import { useStudyPlan } from "../../../../../../app/contexts/study.context";
import * as XLSX from "xlsx";
import PrimaryButton from "../../../../../../app/components/buttons/primary";
import SecondaryButton from "../../../../../../app/components/buttons/secondary";

// Helper function to format Excel date values
const formatExcelDate = (value) => {
  if (typeof value === "number" && value > 1000) {
    return XLSX.SSF.format("dd-mmm-yy", value);
  }
  return value;
};

const extractAdditionalFields = (sheetData, studyPlan) => {
  // Helper function to get value or null if undefined
  const getValueOrNull = (value) => value === undefined ? null : value;

  // Helper function to convert date string to milliseconds
  const convertToMillis = (dateStr) => {
    if (!dateStr) return null;
    const date = new Date(dateStr);
    return !isNaN(date.getTime()) ? date.getTime() : null;
  };

  const calculateSitesToActivate = (sheetData) => {
    // First check cell D10 (index [9][3])
    const cellD10Value = sheetData[9]?.[3];
    
    if (cellD10Value !== null && cellD10Value !== undefined && cellD10Value !== '') {
      const numValue = Number(cellD10Value);
      if (!isNaN(numValue)) {
        return numValue;
      }
    } 
    // Sum up values from F29 to F58
    let sum = 0;
    for (let i = 28; i < 58; i++) {
      const value = sheetData[i]?.[5];  // Column F is index 5
      if (value !== null && value !== undefined && value !== '') {
        const numValue = typeof value === 'number' ? value : Number(value.toString().trim());
        if (!isNaN(numValue)) {
          sum += numValue;
        }
      }
    }
    return sum > 0 ? sum : null;
  };

  const sitesToActivate = studyPlan.parameters?.find(param => param.parameterName === 'SITES_TO_ACTIVATE');
  const subjectsToScreen = studyPlan.parameters?.find(param => param.parameterName === 'SUBJECTS_TO_SCREEN');
  const subjectsToEnroll = studyPlan.milestones?.find(milestone => milestone.milestoneName === 'NUM_SUBJECTS_ENROLLED');
  const subjectsToTreat = studyPlan.milestones?.find(milestone => milestone.milestoneName === 'NUM_SUBJECTS_TREATED');
  const subjectsToComplete = studyPlan.milestones?.find(milestone => milestone.milestoneName === 'NUM_SUBJECTS_COMPLETED');

  // Extract fields from Excel
  const extractedData = {
    // Values from studyPlan
    studyPlanId: studyPlan.studyPlanId,
    studyId: studyPlan.studyId,
    studyPlanType: studyPlan.studyPlanType,
    cohortType: studyPlan.cohortType,

    // Study Details (root level)
    studyPlanName: getValueOrNull(sheetData[4]?.[3]),        // Cell D5
    studyPlanDescr: getValueOrNull(sheetData[5]?.[3]),       // Cell D6
    
    // General Parameters (root level)
    croProjectStartDateMillis: convertToMillis(getValueOrNull(formatExcelDate(sheetData[4]?.[9]))),     // Cell J5
    finalProtocolStartDateMillis: convertToMillis(getValueOrNull(formatExcelDate(sheetData[5]?.[9]))),  // Cell J6
    overallEnrollmentDuration: getValueOrNull(sheetData[7]?.[3]),   // Cell D8
    enrollmentRate: getValueOrNull(sheetData[8]?.[3]),              // Cell D9
    screeningPeriodDuration: getValueOrNull(sheetData[12]?.[3]),    // Cell D13
    treatmentDuration: getValueOrNull(sheetData[13]?.[3]),          // Cell D14
    followUpDuration: getValueOrNull(sheetData[14]?.[3]),           // Cell D15
    screenFailRate: getValueOrNull(sheetData[17]?.[7]),             // Cell H18
    dropOutRate: getValueOrNull(sheetData[18]?.[7]),                // Cell H19
    pauseEnrollmentAfterPatient: getValueOrNull(sheetData[22]?.[3]),// Cell D23
    pauseEnrollmentDays: getValueOrNull(sheetData[23]?.[3]),        // Cell D24

    // Parameters object
    parameters: [
      {
        studyPlanParameterId: subjectsToScreen.studyPlanParameterId,
        parameterScope: "STUDY",
        parameterId: 13,
        lowerBound: getValueOrNull(sheetData[16]?.[3]),    // Cell D17
        upperBound: null
      },
      {
        studyPlanParameterId: sitesToActivate?.studyPlanParameterId,
        parameterScope: "STUDY",
        parameterId: 12,
        lowerBound: calculateSitesToActivate(sheetData),
        upperBound: null
      }
    ],

    // Milestones object
    milestones: [
      {
        studyPlanMilestoneGoalId: subjectsToEnroll.studyPlanMilestoneGoalId,
        milestoneScope: "STUDY",
        milestoneId: 144,
        lowerBound: getValueOrNull(sheetData[17]?.[3]),    // Cell D18
        upperBound: null
      },
      {
        studyPlanMilestoneGoalId: subjectsToTreat.studyPlanMilestoneGoalId,
        milestoneScope: "STUDY",
        milestoneId: 147,
        lowerBound: getValueOrNull(sheetData[18]?.[3]),    // Cell D19
        upperBound: null
      },
      {
        studyPlanMilestoneGoalId: subjectsToComplete.studyPlanMilestoneGoalId,
        milestoneScope: "STUDY",
        milestoneId: 150,
        lowerBound: getValueOrNull(sheetData[19]?.[3]),    // Cell D20
        upperBound: null
      }
    ]
  };

  return extractedData;
};

const UploadPlanModal = ({ onContinue, ...props }) => {
  const { studyPlan } = useStudyPlan();
  const { showSnackbar } = useSnackbar();
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [apiStatus, setApiStatus] = useState(null);
  const [sheetData, setSheetData] = useState([]);
  const [newStudyPlanId, setNewStudyPlanId] = useState(null); // Add this line
  const handleChange = async (files) => {
    if (files.length === 0) return;
    handleUpload(files);
  };

  const handleUpload = async (files) => {
    if (files.length === 0) return;

    setLoading(true);
    setProgress(0);
    setStatus("Uploading plan...");

    try {
      const reader = new FileReader();
      reader.onload = async (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const sheetData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        setSheetData(sheetData);

        // First create and send additionalFields
        const additionalFields = extractAdditionalFields(sheetData, studyPlan);
        
        try {
          // First API call - updatePlan
          const updateResponse = await API.updatePlan(additionalFields, studyPlan.studyId);
          
          if (updateResponse.status === 200 && updateResponse.data[0].returnCode === "200") {
            // Get the new study ID from the response
            const newStudyPlanId = updateResponse.data[0].Id;
            setNewStudyPlanId(updateResponse.data[0].Id);

            // Create JSON structure from "Country Inputs" table (rows 7 to 36)
            const countryInputsData = sheetData
              .slice(28, 58)
              .filter((row) => row.some((cell) => cell !== undefined && cell !== null && cell !== ""))
              .map((row) => {
                const [
                  countryName,
                  siteIdEffort,
                  countryStartUpTimeFrame,
                  enrollmentRate,
                  NUM_SITES_ACTIVATED_LOWER,
                  NUM_SITES_ACTIVATED_UPPER,
                  NUM_SUBJECTS_SCREENED_LOWER,
                  NUM_SUBJECTS_SCREENED_UPPER,
                  screenFailRate,
                  NUM_SUBJECTS_ENROLLED,
                  dropOutRate,
                  NUM_SUBJECTS_TREATED,
                  NUM_SUBJECTS_COMPLETED,
                ] = row.slice(1);

                return {
                  countryName,
                  siteIdEffort,
                  countryStartUpTimeFrame,
                  enrollmentRate: enrollmentRate === "" ? null : enrollmentRate,
                  NUM_SITES_ACTIVATED_LOWER,
                  NUM_SITES_ACTIVATED_UPPER,
                  NUM_SUBJECTS_SCREENED_LOWER,
                  NUM_SUBJECTS_SCREENED_UPPER,
                  screenFailRate,
                  NUM_SUBJECTS_ENROLLED,
                  dropOutRate,
                  NUM_SUBJECTS_TREATED,
                  NUM_SUBJECTS_COMPLETED,
                };
              });

            // Create an array of country names
            const countryNames = countryInputsData.map(
              (country) => country.countryName
            );

            // Create JSON structure for Milestones
            const milestonesData = sheetData
              .slice(63, 93)
              .filter((row) => row.some((cell) => cell !== undefined && cell !== null && cell !== ""))
              .map((row, index) => {
                const [
                  _,
                  SITE_ID_DATE,
                  SITE_ID_COMMENT,
                  SUBMISSION_DATE,
                  SUBMISSION_COMMENT,
                  FIRST_REGULATORY_APPROVAL_DATE,
                  FIRST_REGULATORY_APPROVAL_COMMENT,
                  FSIV_DATE,
                  FSIV_COMMENT,
                  FPS_DATE,
                  FPS_COMMENT,
                  FPI_DATE,
                  FPI_COMMENT,
                  LPI_DATE,
                  LPI_COMMENT,
                ] = row.slice(1);

                return {
                  countryName: countryNames[index],
                  SITE_ID_DATE: formatExcelDate(SITE_ID_DATE),
                  SITE_ID_COMMENT,
                  SUBMISSION_DATE: formatExcelDate(SUBMISSION_DATE),
                  SUBMISSION_COMMENT,
                  FIRST_REGULATORY_APPROVAL_DATE: formatExcelDate(FIRST_REGULATORY_APPROVAL_DATE),
                  FIRST_REGULATORY_APPROVAL_COMMENT,
                  FSIV_DATE: formatExcelDate(FSIV_DATE),
                  FSIV_COMMENT,
                  FPS_DATE: formatExcelDate(FPS_DATE),
                  FPS_COMMENT,
                  FPI_DATE: formatExcelDate(FPI_DATE),
                  FPI_COMMENT,
                  LPI_DATE: formatExcelDate(LPI_DATE),
                  LPI_COMMENT,
                };
              });

            // Create JSON structure for Activation Profiles
            const activationProfilesData = sheetData
              .slice(98, 128)
              .filter((row) => row.some((cell) => cell !== undefined && cell !== null && cell !== ""))
              .map((row, index) => {
                const [
                  _,
                  CARD_1_PERCENT,
                  CARD_1_DATE,
                  CARD_2_PERCENT,
                  CARD_2_DATE,
                  CARD_3_PERCENT,
                  CARD_3_DATE,
                  CARD_4_PERCENT,
                  CARD_4_DATE,
                  CARD_5_PERCENT,
                  CARD_5_DATE,
                  CARD_6_PERCENT,
                  CARD_6_DATE,
                  CARD_7_PERCENT,
                  CARD_7_DATE,
                  CARD_8_PERCENT,
                  CARD_8_DATE,
                ] = row.slice(1);

                return {
                  countryName: countryNames[index],
                  CARD_1_PERCENT,
                  CARD_1_DATE: formatExcelDate(CARD_1_DATE),
                  CARD_2_PERCENT,
                  CARD_2_DATE: formatExcelDate(CARD_2_DATE),
                  CARD_3_PERCENT,
                  CARD_3_DATE: formatExcelDate(CARD_3_DATE),
                  CARD_4_PERCENT,
                  CARD_4_DATE: formatExcelDate(CARD_4_DATE),
                  CARD_5_PERCENT,
                  CARD_5_DATE: formatExcelDate(CARD_5_DATE),
                  CARD_6_PERCENT,
                  CARD_6_DATE: formatExcelDate(CARD_6_DATE),
                  CARD_7_PERCENT,
                  CARD_7_DATE: formatExcelDate(CARD_7_DATE),
                  CARD_8_PERCENT,
                  CARD_8_DATE: formatExcelDate(CARD_8_DATE),
                };
              });

// Create JSON structure for Enrollment Seasonal Variations
const enrollmentSeasonalVariations = sheetData
  .slice(131, 161)
  .filter((row) => row.some((cell) => cell !== undefined && cell !== null && cell !== ""))
  .map((row, index) => ({
    countryName: countryNames[index],
    MONTH_1: row[2],
    MONTH_2: row[3],
    MONTH_3: row[4],
    MONTH_4: row[5],
    MONTH_5: row[6],
    MONTH_6: row[7],
    MONTH_7: row[8],
    MONTH_8: row[9],
    MONTH_9: row[10],
    MONTH_10: row[11],
    MONTH_11: row[12],
    MONTH_12: row[13],
  }));

  // Create JSON structure for Site Fatigue
  const siteFatigue = sheetData
    .slice(164, 194)
    .filter((row) => row.some((cell) => cell !== undefined && cell !== null && cell !== ""))
    .map((row, index) => ({
      countryName: countryNames[index],
      SITE_FATIGUE_DAYS: row[2],
      SITE_FATIGUE_PERCENT: row[3],
    }));
  
  const payload = {
    studyId: studyPlan.studyId, // Use the new study ID from updateResponse
    studyPlanId: newStudyPlanId,
    studyPlanType: studyPlan.studyPlanType,
    tables: [
      {
        tableName: "Country Inputs",
        countries: countryInputsData,
      },
      {
        tableName: "Milestones",
        countries: milestonesData,
      },
      {
        tableName: "Activation Profiles",
        countries: activationProfilesData,
      },
      {
        tableName: "Enrollment Seasonal Variations",
        countries: enrollmentSeasonalVariations,
      },
      {
        tableName: "Site Fatigue",
        countries: siteFatigue,
      },
    ],
  };

  try {
    // Second API call using the new study ID
    const uploadResponse = await API.uploadPlan(studyPlan.studyId, payload);
    if (uploadResponse.status === 200) {
      showSnackbar("Plan uploaded and updated successfully", "success");
      setProgress(100);
      setStatus("Upload completed.");
      setApiStatus(uploadResponse.status);
    } else {
      showSnackbar("Plan upload failed", "warning");
      setProgress(100);
      setStatus("Upload failed.");
      setApiStatus(uploadResponse.status);
      setSheetData(uploadResponse.text);
    }
  } catch (uploadError) {
    console.error('Upload API Error:', uploadError);
    showSnackbar("Plan upload failed", "error");
    setProgress(100);
    setStatus("Upload failed.");
  }
            } else {
              showSnackbar("Failed to update plan", "error");
              setProgress(100);
              setStatus("Update failed.");
              setApiStatus(updateResponse.data[0].returnCode);
              setSheetData(updateResponse.data[0].returnDesc);
            }
          } catch (error) {
            console.error('API Error:', error);
            showSnackbar("Failed to update plan", "error");
            setLoading(false);
          }
        };
        
        reader.readAsArrayBuffer(files[0]);
      } catch (error) {
        console.error('File Reading Error:', error);
        showSnackbar("Failed to read file", "error");
        setLoading(false);
      }
    };

    const lastContinue = () => {
      props.onClose();
      // Pass the newStudyPlanId to the onContinue callback
      onContinue(newStudyPlanId);
    };
  return (
    <CustomModal
      size={700}
      {...props}
      onClose={!loading && props.onClose}
      aria-labelledby="Upload Plan"
      aria-describedby="upload-plan"
      title="Upload Plan"
    >
      <Container>
        {!loading && (
          <VContainer>
            <FileUploader
              acceptedFiles={{
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                  [],
              }}
              onFileUpload={(event) => handleChange(event)}
            />
          </VContainer>
        )}
        {loading && (
          <VContainer>
            {status !== "Upload completed." && status !== "Upload failed." && (
              <LinearProgress
                variant="determinate"
                value={progress}
              />
            )}
            <div>{status}</div>
            {apiStatus !== 200 && progress === 100 && (
              <>
                <div>
                  System Response:
                  <br />
                  {sheetData}
                </div>
              </>
            )}
            <ButtonContainer>
              {apiStatus === 200 ? (
                <PrimaryButton onClick={lastContinue}>Continue</PrimaryButton>
              ) : (
                <SecondaryButton onClick={props.onClose}>
                  Cancel
                </SecondaryButton>
              )}
            </ButtonContainer>
          </VContainer>
        )}
        {!loading && (
          <ButtonContainer>
            <SecondaryButton onClick={props.onClose}>Cancel</SecondaryButton>
          </ButtonContainer>
        )}
      </Container>
    </CustomModal>
  );
};

export default UploadPlanModal;

const VContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2em;
  padding-top: 0;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
  padding: 1em;
  padding-bottom: 0em;
  padding-top: 0;
  max-height: 75vh;
  overflow-y: auto;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1em;
`;
