import React, { useState } from "react";
import AddCountryModal from "./add.country.modal";
import AddCtisSubmissionModal from "./add.ctis.submission.modal";
import { ArrowUpward } from "@material-ui/icons";
import PublicIcon from "@material-ui/icons/Public";
import { makeStyles } from "@material-ui/styles";
import styled from "styled-components";
import TertiaryButton from "../../../../../app/components/buttons/tertiary/index.js";

const Controls = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openSubmissionModal, setOpenSubmissionModal] = useState(false);
  const classes = useStyles();

  const handleSave = () => {};

  const handleSTISSave = () => {
    // Placeholder function for handling CTIS submission save action
    // window.location.reload();
  };

  return (
    <Container>
      <TertiaryButton onClick={() => setOpenModal(true)}>
        <PublicIcon classes={classes} /> Add Country
      </TertiaryButton>
      <TertiaryButton onClick={() => setOpenSubmissionModal(true)}>
        <ArrowUpward classes={classes} />
        CTIS Submission
      </TertiaryButton>

      {openModal && (
        <AddCountryModal
          open={openModal}
          onClose={() => setOpenModal(false)}
          onSave={handleSave}
        />
      )}
      {openSubmissionModal && (
        <AddCtisSubmissionModal
          open={openSubmissionModal}
          onClose={() => setOpenSubmissionModal(false)}
          onSave={handleSTISSave}
        />
      )}
    </Container>
  );
};

export default Controls;

// Styled component for the control panel container
const Container = styled.div`
  display: flex;
  justify-content: flex-end;
`;

// Custom styles for Material-UI components
const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: ".2em",
  },
}));
